import React from "react"
import Header from "../components/header"
import Footer from "../components/footer"
import PageTransition from 'gatsby-v2-plugin-page-transitions'
import { Helmet } from "react-helmet"
import {isMobile} from 'react-device-detect'
import { Link } from "gatsby"

export default function Architecture() {
  return (

    <PageTransition
    transitionStyles={{
    entering: { opacity: 0 },
    entered: { opacity: 1 },
    exiting: { opacity: 1 },
    exited: { opacity: 0 },
    }}
  >
      <div className="wrapper">

        <Helmet>
          <meta charSet="utf-8" />
          <title>Architecture - Governors Point</title>
          <meta name="description" content="Governors point land LP purchased the peninsula in 2018 with a unique and poetic intention. The broad goals include preserving its delicate ecology, sharing it with the local community and only intervening with an architectural language sensitive to the site and its narrative." />
          <link rel="canonical" href="https://governorspoint.com" />
          <body className={isMobile ? `mobile` : ``} />
        </Helmet>

        <Header menuActive="" />

        <div className="main">

          <div className="container">

            <div className="module featured-img img-right">
              <figure data-sal="slide-up" data-sal-duration="1000" data-sal-easing="ease"><img src="/img/architecture-01.jpg" alt="" /></figure>
              <div className="img-text">
                <div className="flex-grid copy">
                  <div>
                    <div className="image-copy-width">
                      <p>A maximum of 3000 square feet around each property can be landscaped to preserve the natural ecology of the site. All homes are west-facing, obscured from Chuckanut Drive (the historic scenic driving route) and visible only from the water. From the water, instead of angular glass architecture, passing kayakers might notice cloud-like silhouettes floating at the forest edge.</p>
                      <p>The seaward facades are clad in tumbled cedar burl offcuts (a waste product from the local logging industry), suspended on metal rods to form an organic, curved wooden skin. Over time, the burls will attract mosses and lichens to create a living veil.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="module two-col-imgs text-right">
              <div className="flex-grid" data-sal="slide-up" data-sal-duration="1000" data-sal-easing="ease">
                <div className="col-6">
                  <figure><img src="/img/architecture-02.jpg" alt="" /></figure>
                </div>
                <div className="col-6">
                  <figure><img src="/img/architecture-03.jpg" alt="" /></figure>
                </div>
              </div>
              <div className="img-text">
                <div className="flex-grid caption">
                  <div>
                    <div className="image-caption-width">
                      <p>Left</p>
                    </div>
                  </div>
                  <div>
                    <div className="image-desc-width">
                      <p>Donec sagittis porta velit, id tincidunt enim pretium cras varius accumsan fringilla.</p>
                    </div>
                  </div>
                </div>
                <div className="flex-grid caption">
                  <div>
                    <div className="image-caption-width">
                      <p>Right</p>
                    </div>
                  </div>
                  <div>
                    <div className="image-desc-width">
                      <p>Lorem ipsum dolor sit amet, consectetur adipiscing. Pretium cras varius accumsan.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="module two-col-imgs text-left">
              <div className="flex-grid" data-sal="slide-up" data-sal-duration="1000" data-sal-easing="ease">
                <div className="col-6">
                  <figure><img src="/img/architecture-04.jpg" alt="" /></figure>
                </div>
                <div className="col-6">
                  <figure><img src="/img/architecture-05.jpg" alt="" /></figure>
                </div>
              </div>
              <div className="img-text">
                <div className="flex-grid caption">
                  <div>
                    <div className="image-caption-width">
                      <p>Left</p>
                    </div>
                  </div>
                  <div>
                    <div className="image-desc-width">
                      <p>Donec sagittis porta velit, id tincidunt enim pretium cras varius accumsan fringilla.</p>
                    </div>
                  </div>
                </div>
                <div className="flex-grid caption">
                  <div>
                    <div className="image-caption-width">
                      <p>Right</p>
                    </div>
                  </div>
                  <div>
                    <div className="image-desc-width">
                      <p>Lorem ipsum dolor sit amet, consectetur adipiscing. Pretium cras varius accumsan.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="module featured-img unique-img-01">
              <figure data-sal="slide-up" data-sal-duration="1000" data-sal-easing="ease"><img src="/img/architecture-06.png" alt="" /></figure>
            </div>

            <div className="module featured-img">
              <div className="video-wrap" data-sal="slide-up" data-sal-duration="1000" data-sal-easing="ease">
                <iframe src="https://player.vimeo.com/video/536435953" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" title="video" allowfullscreen></iframe>
              </div>
              <div className="img-text">
                <div className="flex-grid text-wrap">
                  <div>
                    <div className="flex-grid caption">
                      <div>
                        <div className="image-caption-width">
                          <p>Above 1</p>
                        </div>
                      </div>
                      <div>
                        <div className="image-desc-width">
                          <p>Sagittis porta velit, id tincidunt enim pretium cras accumsan fringilla eget ipsum.</p>
                        </div>
                      </div>
                    </div>
                    <div className="flex-grid caption">
                      <div>
                        <div className="image-caption-width">
                          <p>Above 2</p>
                        </div>
                      </div>
                      <div>
                        <div className="image-desc-width">
                          <p>Sagittis porta velit, id tincidunt enim pretium cras accumsan fringilla eget ipsum.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="flex-grid copy">
                      <div>
                        <div className="image-copy-width">
                          <p>16 coastal homesites have been apportioned to minimise intrusion into the feeling and experience of the site as a whole. The approach is for design to bring people and nature together imaginatively and sensitively. Architecture and site are deemed symbiotic, not as elements in opposition. The results are homes that enable residents’ appreciation of the natural order without compromise, nor disturbance of the landscape at large.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="module two-col-imgs text-left">
              <div className="flex-grid" data-sal="slide-up" data-sal-duration="1000" data-sal-easing="ease">
                <div className="col-6">
                  <figure><img src="/img/architecture-07.jpg" alt="" /></figure>
                </div>
                <div className="col-6">
                  <figure><img src="/img/architecture-08.jpg" alt="" /></figure>
                </div>
              </div>
              <div className="img-text">
                <div className="flex-grid caption">
                  <div>
                    <div className="image-caption-width">
                      <p>Left</p>
                    </div>
                  </div>
                  <div>
                    <div className="image-desc-width">
                      <p>Donec sagittis porta velit, id tincidunt enim pretium cras varius accumsan fringilla.</p>
                    </div>
                  </div>
                </div>
                <div className="flex-grid caption">
                  <div>
                    <div className="image-caption-width">
                      <p>Right</p>
                    </div>
                  </div>
                  <div>
                    <div className="image-desc-width">
                      <p>Lorem ipsum dolor sit amet, consectetur adipiscing. Pretium cras varius accumsan.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="module two-col-imgs text-right">
              <div className="flex-grid" data-sal="slide-up" data-sal-duration="1000" data-sal-easing="ease">
                <div className="col-6">
                  <figure><img src="/img/architecture-09.jpg" alt="" /></figure>
                </div>
                <div className="col-6">
                  <figure><img src="/img/architecture-10.jpg" alt="" /></figure>
                </div>
              </div>
              <div className="img-text">
                <div className="flex-grid caption">
                  <div>
                    <div className="image-caption-width">
                      <p>Left</p>
                    </div>
                  </div>
                  <div>
                    <div className="image-desc-width">
                      <p>Donec sagittis porta velit, id tincidunt enim pretium cras varius accumsan fringilla.</p>
                    </div>
                  </div>
                </div>
                <div className="flex-grid caption">
                  <div>
                    <div className="image-caption-width">
                      <p>Right</p>
                    </div>
                  </div>
                  <div>
                    <div className="image-desc-width">
                      <p>Lorem ipsum dolor sit amet, consectetur adipiscing. Pretium cras varius accumsan.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="module featured-img">
              <figure data-sal="slide-up" data-sal-duration="1000" data-sal-easing="ease"><img src="/img/architecture-11.jpg" alt="" /></figure>
            </div>

            <div className="module two-col-imgs text-left">
              <div className="flex-grid" data-sal="slide-up" data-sal-duration="1000" data-sal-easing="ease">
                <div className="col-6">
                  <figure><img src="/img/architecture-12.jpg" alt="" /></figure>
                </div>
                <div className="col-6">
                  <figure><img src="/img/architecture-13.jpg" alt="" /></figure>
                </div>
              </div>
              <div className="img-text">
                <div className="flex-grid caption">
                  <div>
                    <div className="image-caption-width">
                      <p>Left</p>
                    </div>
                  </div>
                  <div>
                    <div className="image-desc-width">
                      <p>Donec sagittis porta velit, id tincidunt enim pretium cras varius accumsan fringilla.</p>
                    </div>
                  </div>
                </div>
                <div className="flex-grid caption">
                  <div>
                    <div className="image-caption-width">
                      <p>Right</p>
                    </div>
                  </div>
                  <div>
                    <div className="image-desc-width">
                      <p>Lorem ipsum dolor sit amet, consectetur adipiscing. Pretium cras varius accumsan.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="module two-col-imgs text-right">
              <div className="flex-grid" data-sal="slide-up" data-sal-duration="1000" data-sal-easing="ease">
                <div className="col-6">
                  <figure><img src="/img/architecture-14.jpg" alt="" /></figure>
                </div>
                <div className="col-6">
                  <figure><img src="/img/architecture-15.jpg" alt="" /></figure>
                </div>
              </div>
              <div className="img-text">
                <div className="flex-grid caption">
                  <div>
                    <div className="image-caption-width">
                      <p>Left</p>
                    </div>
                  </div>
                  <div>
                    <div className="image-desc-width">
                      <p>Donec sagittis porta velit, id tincidunt enim pretium cras varius accumsan fringilla.</p>
                    </div>
                  </div>
                </div>
                <div className="flex-grid caption">
                  <div>
                    <div className="image-caption-width">
                      <p>Right</p>
                    </div>
                  </div>
                  <div>
                    <div className="image-desc-width">
                      <p>Lorem ipsum dolor sit amet, consectetur adipiscing. Pretium cras varius accumsan.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="module featured-img unique-img-02">
              <figure data-sal="slide-up" data-sal-duration="1000" data-sal-easing="ease"><img src="/img/architecture-16.png" alt="" /></figure>
              <div className="img-text">
                <div className="flex-grid text-wrap">
                  <div>
                    <div className="flex-grid caption">
                      <div>
                        <div className="image-caption-width">
                          <p>Above</p>
                        </div>
                      </div>
                      <div>
                        <div className="image-desc-width">
                          <p>Sagittis porta velit, id tincidunt enim pretium cras accumsan fringilla eget ipsum.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="flex-grid copy">
                      <div>
                        <div className="image-copy-width">
                          <p>Vancouver architect Omer Arbel has designed a blueprint for the residences following a covenant of land first restrictions to ensure any intervention is careful and consistent. Each home is limited to a modest size of 2900 square feet (with an average lot size of 75,000 square feet) to keep buildings in appropriate scale with their surroundings. </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="module two-col-imgs text-left">
              <div className="flex-grid" data-sal="slide-up" data-sal-duration="1000" data-sal-easing="ease">
                <div className="col-6">
                  <figure><img src="/img/architecture-17.jpg" alt="" /></figure>
                </div>
                <div className="col-6">
                  <figure><img src="/img/architecture-18.jpg" alt="" /></figure>
                </div>
              </div>
              <div className="img-text">
                <div className="flex-grid caption">
                  <div>
                    <div className="image-caption-width">
                      <p>Left</p>
                    </div>
                  </div>
                  <div>
                    <div className="image-desc-width">
                      <p>Donec sagittis porta velit, id tincidunt enim pretium cras varius accumsan fringilla.</p>
                    </div>
                  </div>
                </div>
                <div className="flex-grid caption">
                  <div>
                    <div className="image-caption-width">
                      <p>Right</p>
                    </div>
                  </div>
                  <div>
                    <div className="image-desc-width">
                      <p>Lorem ipsum dolor sit amet, consectetur adipiscing. Pretium cras varius accumsan.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="module two-col-imgs text-right">
              <div className="flex-grid" data-sal="slide-up" data-sal-duration="1000" data-sal-easing="ease">
                <div className="col-6">
                  <figure><img src="/img/architecture-19.jpg" alt="" /></figure>
                </div>
                <div className="col-6">
                  <figure><img src="/img/architecture-20.jpg" alt="" /></figure>
                </div>
              </div>
              <div className="img-text">
                <div className="flex-grid caption">
                  <div>
                    <div className="image-caption-width">
                      <p>Left</p>
                    </div>
                  </div>
                  <div>
                    <div className="image-desc-width">
                      <p>Donec sagittis porta velit, id tincidunt enim pretium cras varius accumsan fringilla.</p>
                    </div>
                  </div>
                </div>
                <div className="flex-grid caption">
                  <div>
                    <div className="image-caption-width">
                      <p>Right</p>
                    </div>
                  </div>
                  <div>
                    <div className="image-desc-width">
                      <p>Lorem ipsum dolor sit amet, consectetur adipiscing. Pretium cras varius accumsan.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="module all-lots" id="all-lots">
              <div className="lots-wrap">
                <div className="lot-item">
                  <Link to="/lots/lot-1/">
                    <div className="flex-grid">
                      <div className="col-6 col-md-2 name">Lot 1</div>
                      <div className="col-6 col-md-4 size">0.9 Acre</div>
                      <div className="col-md-5 img"><figure><img src="/img/architecture-21.jpg" alt="" /></figure></div>
                    </div>
                  </Link>
                </div>
                <div className="lot-item">
                  <Link to="/lots/lot-2/">
                    <div className="flex-grid">
                      <div className="col-6 col-md-2 name">Lot 2</div>
                      <div className="col-6 col-md-4 size">1.78 Acre</div>
                      <div className="col-md-5 img"><figure><img src="/img/architecture-21.jpg" alt="" /></figure></div>
                    </div>
                  </Link>
                </div>
                <div className="lot-item">
                  <Link to="/lots/lot-3/">
                    <div className="flex-grid">
                      <div className="col-6 col-md-2 name">Lot 3</div>
                      <div className="col-6 col-md-4 size">2.01 Acre</div>
                      <div className="col-md-5 img"><figure><img src="/img/architecture-21.jpg" alt="" /></figure></div>
                    </div>
                  </Link>
                </div>
                <div className="lot-item">
                  <Link to="/lots/lot-4/">
                    <div className="flex-grid">
                      <div className="col-6 col-md-2 name">Lot 4</div>
                      <div className="col-6 col-md-4 size">1.65 Acre</div>
                      <div className="col-md-5 img"><figure><img src="/img/architecture-21.jpg" alt="" /></figure></div>
                    </div>
                  </Link>
                </div>
                <div className="lot-item">
                  <Link to="/lots/lot-5/">
                    <div className="flex-grid">
                      <div className="col-6 col-md-2 name">Lot 5</div>
                      <div className="col-6 col-md-4 size">1.29 Acre</div>
                      <div className="col-md-5 img"><figure><img src="/img/architecture-21.jpg" alt="" /></figure></div>
                    </div>
                  </Link>
                </div>
                <div className="lot-item">
                  <Link to="/lots/lot-6/">
                    <div className="flex-grid">
                      <div className="col-6 col-md-2 name">Lot 6</div>
                      <div className="col-6 col-md-4 size">1.23 Acre</div>
                      <div className="col-md-5 img"><figure><img src="/img/architecture-21.jpg" alt="" /></figure></div>
                    </div>
                  </Link>
                </div>
                <div className="lot-item">
                  <Link to="/lots/lot-7/">
                    <div className="flex-grid">
                      <div className="col-6 col-md-2 name">Lot 7</div>
                      <div className="col-6 col-md-4 size">1.40 Acre</div>
                      <div className="col-md-5 img"><figure><img src="/img/architecture-21.jpg" alt="" /></figure></div>
                    </div>
                  </Link>
                </div>
                <div className="lot-item">
                  <Link to="/lots/lot-8/">
                    <div className="flex-grid">
                      <div className="col-6 col-md-2 name">Lot 8</div>
                      <div className="col-6 col-md-4 size">1.49 Acre</div>
                      <div className="col-md-5 img"><figure><img src="/img/architecture-21.jpg" alt="" /></figure></div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
            
          </div>
        </div>

        <Footer />

      </div>
    </PageTransition>

  )
}
